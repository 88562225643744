<template>
    <div></div>
</template>

<script>

export default {
  name: 'Progress',
}

</script>